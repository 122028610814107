import React, { useEffect, useState } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

// Import Logo
import sonyfavicon from "images/favicon.ico";
import sonyLogo from "images/sony-logo.svg";

// Import Main Icons
import dashboardicon from "icons/dashboard-icon.svg";
import requesticon from "icons/requests-icon.svg";
import networkicon from "icons/network-icon.svg";
import selfservicesicon from "icons/self-services-icon.svg";
import utilitiesicon from "icons/utilities-icon.svg";
import cloudeyeicon from "icons/cloudeye-icon.svg";
import documentsicon from "icons/documents-icon.svg";

// Import Sub Icons
import accountrequestbg from "icons/sub-menu-icons/account-request-bg.svg";
import accountrequestcolor from "icons/sub-menu-icons/account-request-color.svg";

import awsbg from "icons/sub-menu-icons/awsbg.svg";
import awscolor from "icons/sub-menu-icons/awscolor.svg";

import azurebg from "icons/sub-menu-icons/azurebg.svg";
import azurecolor from "icons/sub-menu-icons/azurecolor.svg";

import gitlabbg from "icons/sub-menu-icons/gitlabbg.svg";
import gitlabcolor from "icons/sub-menu-icons/gitlabcolor.svg";

import notifybg from "icons/sub-menu-icons/notifybg.svg";
import notifycolor from "icons/sub-menu-icons/notifycolor.svg";

import accountsearchbg from "icons/sub-menu-icons/account-search-bg.svg";
import accountsearchcolor from "icons/sub-menu-icons/account-search-color.svg";

import oktabg from "icons/sub-menu-icons/okta-bg.svg";
import oktacolor from "icons/sub-menu-icons/okta-color.svg";

import moriartytriggerbg from "icons/sub-menu-icons/moriarty-trigger-bg.svg";
import moriartytriggercolor from "icons/sub-menu-icons/moriarty-trigger-color.svg";

import passportbg from "icons/sub-menu-icons/passport-bg.svg";
import passportcolor from "icons/sub-menu-icons/passport-color.svg";

import workspacerequestbg from "icons/sub-menu-icons/workspace-request-bg.svg";
import workspacerequestcolor from "icons/sub-menu-icons/workspace-request-color.svg";

import requesttrainingbg from "icons/sub-menu-icons/request-training-bg.svg";
import requesttrainingcolor from "icons/sub-menu-icons/request-training-color.svg";

import networkhubbg from "icons/sub-menu-icons/Networkhub-bg.svg";
import networkhubcolor from "icons/sub-menu-icons/Networkhub-color.svg";

import hubsecurityIcon from "icons/hub-security.svg";

import manageadbg from "icons/sub-menu-icons/manage-ad-bg.svg";
import manageadcolor from "icons/sub-menu-icons/manage-ad-color.svg";

import billingbg from "icons/billing-bg.svg";
import billingcolor from "icons/billing-color.svg";

import templatebg from "icons/sub-menu-icons/template-bg.svg";
import templatecolor from "icons/sub-menu-icons/template-color.svg";
import awsvpcbg from "icons/sub-menu-icons/aws-vpc-icon.svg";
import awsvpcbgColor from "icons/sub-menu-icons/aws-vpc-icon-color.svg";
import accountUpdateIcon from "icons/sub-menu-icons/cloud-plus-fill.svg";
import accountUpdateIconColor from "icons/sub-menu-icons/cloud-plus-fill-color.svg";
import TMIcon from "icons/sub-menu-icons/patch-check-fill.svg";
import TMIconColor from "icons/sub-menu-icons/patch-check-fill-color.svg";

import patchManagerIcon from "icons/sub-menu-icons/patch-manager.svg";
import patchManagerColor from "icons/sub-menu-icons/patch-manager-color.svg";

import opsCostImg from "images/opsCost-img.svg";
import opsCostImgColor from "images/opsCost-img-color.svg";

import pamCostIcon from "icons/sub-menu-icons/settings-pam.svg";
import pamCostIconColor from "icons/sub-menu-icons/settings-pam-color.svg";

import exception from "icons/sub-menu-icons/exception.svg";
import exceptionColor from "icons/sub-menu-icons/exception-color.svg";

import dashboardIcon from "icons/sub-menu-icons/dashboard-icon.svg";
import dashboardIconColor from "icons/sub-menu-icons/dashboard-icon-color.svg";

import backUpIcon from "icons/sub-menu-icons/icons8-cloud-backup-24.svg";
import backUpIconColor from "icons/sub-menu-icons/icons8-cloud-backup-24-color.svg";

import aiRequestIcon from "images/ai-playground-icon.svg";
import aiRequestIconColor from "images/ai-playground-icon-color.svg";

import {
  CS_Hub_AWS_Account_Request_Admin,
  CS_Hub_AWS_Notification_System,
  CS_Hub_AWS_Account_Search_Read_Only_Security,
  CS_Hub_AWS_Dynamo_Search_Request_Admin,
  CS_Hub_Security_Admin_Branch_1,
  CS_Hub_Security_Admin_Branch_2,
  CS_Hub_CloudEye,
  CS_Hub_CloudEye_Admin,
  CS_Hub_Cloud_Chargeback_Admins,
  CS_Hub_GCS_Chargeback_Admins,
  CS_Hub_PAM_Cost_Admins,
  CS_Hub_Security_Tool_Exception_Approver_Group_2,
  CS_Hub_Security_Tool_Exception_Approver_Group_1,
} from "roles/roles";
import { useTranslation } from "react-i18next";

const SideBar = ({ history }) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [getCloudEyeUserRole, setgetCloudEyeUserRole] = useState("");

  const [isMobileView, setIsMobileView] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 767) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (userInfo !== null) {
      for (var i = 0; i < userInfo.groups.length; i++) {
        const userSearchTerm = CS_Hub_CloudEye;
        const userIndexOfFirst = userInfo.groups[i].indexOf(userSearchTerm);
        if (userIndexOfFirst > -1) {
          setgetCloudEyeUserRole("User");
          break;
        }
      }

      for (var j = 0; j < userInfo.groups.length; j++) {
        const mgmtSearchTerm = "CS Hub-ManagementUser-";
        const mgmtIndexOfFirst = userInfo.groups[j].indexOf(mgmtSearchTerm);
        if (mgmtIndexOfFirst > -1) {
          setgetCloudEyeUserRole("Admin");
          break;
        }
      }
    }
  }, [userInfo]);

  return (
    <>
      {userInfo !== null && (
        // <div className='side-bar side-bar-show' id='side-bar-nav'>
        <div
          className={"side-bar " + (!isMobileView ? "side-bar-show" : "")}
          id="side-bar-nav"
        >
          <nav className="side-bar-nav">
            <div>
              <Link to="/dashboard" className="sidebar-logo-name-wrapper">
                <img
                  src={sonyfavicon}
                  alt="SONY"
                  className="side-bar-logo side-short-logo-visibility"
                  id="sony-short-logo"
                />

                <img
                  src={sonyLogo}
                  alt="SONY"
                  className="side-bar-full-logo"
                  id="sony-full-logo"
                />
              </Link>

              <div>
                <Link
                  to={
                    userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)
                      ? "/dashboard"
                      : "/user-dashboard"
                  }
                  className={
                    "side-bar-link " +
                    (splitLocation[1] === "dashboard" ||
                    splitLocation[1] === "user-dashboard"
                      ? "side-bar-link-active"
                      : "")
                  }
                >
                  <img
                    src={dashboardicon}
                    alt="Dashboard"
                    className="side-bar-menu-logo"
                  />
                  <span className="side-bar-link-name">{t("home")}</span>
                </Link>

                <div
                  className={
                    " side-bar-link " +
                    (splitLocation[1] === "cloud-preference" ||
                    splitLocation[1] === "aws-new-account-request" ||
                    splitLocation[1] === "aws-account-requests" ||
                    splitLocation[1] === "azure-new-account-request" ||
                    splitLocation[1] === "azure-account-requests" ||
                    splitLocation[1] === "managedad-requests-view" ||
                    splitLocation[1] === "managedad-requests-add"
                      ? "side-bar-link-active"
                      : "")
                  }
                  id="requests-menu"
                >
                  <img
                    src={requesticon}
                    alt="Requests"
                    className="side-bar-menu-logo"
                  />
                  <span className="side-bar-link-name">{t("requests")}</span>
                  <div
                    className="arrow-left side-bar-submenu"
                    id="requests-submenu"
                  >
                    <ul>
                      <Link to="/cloud-preference">
                        <li id="accountrequest-requests">
                          <div className="d-flex align-items-center">
                            <img
                              src={accountrequestbg}
                              alt="Account Request"
                              id="accountrequest-requests-img1"
                              className="side-bar-submenu-img"
                            />
                            <img
                              src={accountrequestcolor}
                              alt="Account Request"
                              id="accountrequest-requests-img2"
                              className="side-bar-submenu-img"
                            />
                            <span className="px-3">{t("account_request")}</span>
                          </div>
                        </li>
                      </Link>

                      {/* Workspace Request */}
                      <Link to="/aws-workspaces-view">
                        <li id="workspacerequest-requests">
                          <div className="d-flex align-items-center">
                            <img
                              src={workspacerequestbg}
                              alt="Workspace Request"
                              id="workspacerequest-requests-img1"
                              className="side-bar-submenu-img"
                            />
                            <img
                              src={workspacerequestcolor}
                              alt="Workspace Request"
                              id="workspacerequest-requests-img2"
                              className="side-bar-submenu-img"
                            />
                            <span className="px-3">
                              {t("workspace_request")}
                            </span>
                          </div>
                        </li>
                      </Link>

                      <Link to="/managedad-requests-view">
                        <li id="managead-requests">
                          <div className="d-flex align-items-center">
                            <img
                              src={manageadbg}
                              alt="Manage AD Request"
                              id="managead-requests-img1"
                              className="side-bar-submenu-img"
                            />
                            <img
                              src={manageadcolor}
                              alt="Manage AD Request"
                              id="managead-requests-img2"
                              className="side-bar-submenu-img"
                            />
                            <span className="px-3">
                              {t("manage_ad_request")}
                            </span>
                          </div>
                        </li>
                      </Link>
                      <Link to="/aws-backup-requests">
                        <li id="backup-requests">
                          <div className="d-flex align-items-center">
                            <img
                              src={backUpIcon}
                              alt="Backup Request"
                              id="backup-requests-img1"
                              className="side-bar-submenu-img"
                            />
                            <img
                              src={backUpIconColor}
                              alt="Backup AD Request"
                              id="backup-requests-img2"
                              className="side-bar-submenu-img"
                            />
                            <span className="px-3">{t("Backup Request")}</span>
                          </div>
                        </li>
                      </Link>

                      <Link to="/ai-request-new">
                        <li id="ai-request">
                          <div className="d-flex align-items-center">
                            <img
                              src={aiRequestIcon}
                              alt="AWS"
                              id="aws-ai-img1"
                              className="side-bar-submenu-img"
                            />
                            <img
                              src={aiRequestIconColor}
                              alt="AWS"
                              id="aws-ai-img2"
                              className="side-bar-submenu-img"
                            />
                            <span className="px-3">
                              {t("AI Service Request")}
                            </span>
                          </div>
                        </li>
                      </Link>

                      {/* <Link to="/q-request-new">
                        <li id="q-requests">
                          <div className="d-flex align-items-center">
                            <img
                              src={awsbg}
                              alt="AWS"
                              id="aws-cidr-img1"
                              className="side-bar-submenu-img"
                            />
                            <img
                              src={awscolor}
                              alt="AWS"
                              id="aws-cidr-img2"
                              className="side-bar-submenu-img"
                            />
                            <span className="px-3">
                              {t("Amazon Q Developer")}
                            </span>
                          </div>
                        </li>
                      </Link> */}
                    </ul>
                  </div>
                </div>

                <div
                  className={
                    " side-bar-link " +
                    (splitLocation[1] === "aws-cidr-view-requests" ||
                    splitLocation[1] === "aws-cidr-new-request" ||
                    splitLocation[1] === "azure-cidr-view-requests" ||
                    splitLocation[1] === "azure-cidr-new-request" ||
                    splitLocation[1] === "network-hub-view" ||
                    splitLocation[1] === "network-hub-add"
                      ? "side-bar-link-active"
                      : "")
                  }
                  id="network-menu"
                >
                  <img
                    src={networkicon}
                    alt="Network"
                    className="side-bar-menu-logo"
                  />
                  <span className="side-bar-link-name">{t("network")}</span>
                  <div
                    className="arrow-left side-bar-submenu"
                    id="network-submenu"
                  >
                    <ul>
                      <Link to="/aws-cidr-view-requests">
                        <li id="aws-cidr">
                          <div className="d-flex align-items-center">
                            <img
                              src={awsbg}
                              alt="AWS"
                              id="aws-cidr-img1"
                              className="side-bar-submenu-img"
                            />
                            <img
                              src={awscolor}
                              alt="AWS"
                              id="aws-cidr-img2"
                              className="side-bar-submenu-img"
                            />
                            <span className="px-3">{t("aws_cidr")}</span>
                          </div>
                        </li>
                      </Link>
                      <Link to="/azure-cidr-view-requests">
                        <li id="azure-cidr">
                          <div className="d-flex align-items-center">
                            <img
                              src={azurebg}
                              alt="AWS"
                              id="azure-cidr-img1"
                              className="side-bar-submenu-img"
                            />
                            <img
                              src={azurecolor}
                              alt="AWS"
                              id="azure-cidr-img2"
                              className="side-bar-submenu-img"
                            />
                            <span className="px-3">{t("azure_cidr")}</span>
                          </div>
                        </li>
                      </Link>
                      <Link to="/network-hub-view">
                        <li id="network-hub">
                          <div className="d-flex align-items-center">
                            <img
                              src={networkhubbg}
                              alt="NH"
                              id="network-hub-img1"
                              className="side-bar-submenu-img"
                            />
                            <img
                              src={networkhubcolor}
                              alt="NH"
                              id="network-hub-img2"
                              className="side-bar-submenu-img"
                            />
                            <span className="px-3">{t("network_hub")}</span>
                          </div>
                        </li>
                      </Link>
                      <Link to="/vpc-endpoint-view">
                        <li id="vpc-endpoint">
                          <div className="d-flex align-items-center">
                            <img
                              src={awsvpcbg}
                              alt="VPC"
                              id="vpc-endpoint-img1"
                              className="side-bar-submenu-img"
                            />
                            <img
                              src={awsvpcbgColor}
                              alt="VPC"
                              id="vpc-endpoint-img2"
                              className="side-bar-submenu-img"
                            />
                            <span className="px-3">{t("VPC Endpoint")}</span>
                          </div>
                        </li>
                      </Link>
                    </ul>
                  </div>
                </div>

                {(userInfo.groups.includes(CS_Hub_CloudEye) ||
                  userInfo.groups.includes(CS_Hub_CloudEye_Admin)) && (
                  <Link
                    to={
                      getCloudEyeUserRole === "Admin"
                        ? "/cloudeye-management"
                        : "/cloudeye-user"
                    }
                    // to='/cloudeye-user'
                    className={
                      "side-bar-link " +
                      (splitLocation[1] === "cloudeye-management" ||
                      splitLocation[1] === "cloudeye-user"
                        ? "side-bar-link-active"
                        : "")
                    }
                  >
                    <img
                      src={cloudeyeicon}
                      alt="Cloud Eye"
                      className="side-bar-menu-logo"
                    />
                    <span className="side-bar-link-name">{t("cloud_eye")}</span>
                  </Link>
                )}

                <div
                  className={
                    " side-bar-link " +
                    (splitLocation[1] === "gitlab" ||
                    splitLocation[1] === "okta-custom-role-view" ||
                    splitLocation[1] === "okta-custom-role-add" ||
                    splitLocation[1] === "okta-view-assignment" ||
                    splitLocation[1] === "templates-view" ||
                    splitLocation[1] === "templates-add" ||
                    splitLocation[1] === "aws-account-update-requests" ||
                    splitLocation[1] === "azure-account-update-requests" ||
                    splitLocation[1] === "aws-account-list" ||
                    splitLocation[1] === "azure-account-list" ||
                    splitLocation[1] === "trend-micro-onboarding" ||
                    splitLocation[1] === "trend-micro-azure-requests" ||
                    splitLocation[1] === "trend-micro-azure-account-request" ||
                    splitLocation[1] === "request-training-add" ||
                    splitLocation[1] === "request-training-view" ||
                    splitLocation[1] === "on-demand-scan" ||
                    splitLocation[1] === "account-update"
                      ? "side-bar-link-active"
                      : "")
                  }
                  id="self-service-menu"
                >
                  <img
                    src={selfservicesicon}
                    alt="Self Service"
                    className="side-bar-menu-logo"
                  />
                  <span className="side-bar-link-name">
                    {t("self_service")}
                  </span>
                  <div
                    className="arrow-left side-bar-submenu"
                    id="self-service-submenu"
                  >
                    <ul>
                      <Link to="/gitlab">
                        <li id="gitlab-selfservice">
                          <div className="d-flex align-items-center">
                            <img
                              src={gitlabbg}
                              alt="Gitlab"
                              id="gitlab-selfservice-img1"
                              className="side-bar-submenu-img"
                            />
                            <img
                              src={gitlabcolor}
                              alt="Gitlab"
                              id="gitlab-selfservice-img2"
                              className="side-bar-submenu-img"
                            />
                            <span className="px-3">{t("gitlab")}</span>
                          </div>
                        </li>
                      </Link>

                      {(userInfo.groups.includes(
                        CS_Hub_Security_Admin_Branch_2
                      ) ||
                        userInfo.groups.includes(
                          CS_Hub_AWS_Dynamo_Search_Request_Admin
                        ) ||
                        userInfo.groups.includes(
                          CS_Hub_AWS_Account_Request_Admin
                        )) && (
                        <Link to="/okta-custom-role-view">
                          <li id="okta-selfservice">
                            <div className="d-flex align-items-center">
                              <img
                                src={oktabg}
                                alt="Okta"
                                id="okta-selfservice-img1"
                                className="side-bar-submenu-img"
                              />
                              <img
                                src={oktacolor}
                                alt="Okta"
                                id="okta-selfservice-img2"
                                className="side-bar-submenu-img"
                              />
                              <span className="px-3">{t("okta")}</span>
                            </div>
                          </li>
                        </Link>
                      )}

                      {/* Templates */}
                      <Link to="/templates-view">
                        <li id="templates-selfservice">
                          <div className="d-flex align-items-center">
                            <img
                              src={templatebg}
                              alt="Templates"
                              id="templates-selfservice-img1"
                              className="side-bar-submenu-img"
                            />
                            <img
                              src={templatecolor}
                              alt="Templates"
                              id="templates-selfservice-img2"
                              className="side-bar-submenu-img"
                            />
                            <span className="px-3">{t("templates")}</span>
                          </div>
                        </li>
                      </Link>

                      <Link to="/request-training-view">
                        <li id="request-training-selfservice">
                          <div className="d-flex align-items-center">
                            <img
                              src={requesttrainingbg}
                              alt="Request Training"
                              id="request-training-selfservice-img1"
                              className="side-bar-submenu-img"
                            />
                            <img
                              src={requesttrainingcolor}
                              alt="Request Training"
                              id="request-training-selfservice-img2"
                              className="side-bar-submenu-img"
                            />
                            <span className="px-3">
                              {t("request_training")}
                            </span>
                          </div>
                        </li>
                      </Link>

                      <Link to="/on-demand-scan">
                        <li id="templates-selfservice">
                          <div className="d-flex align-items-center">
                            <img
                              src={templatebg}
                              alt="Templates"
                              id="templates-selfservice-img1"
                              className="side-bar-submenu-img"
                            />
                            <img
                              src={templatecolor}
                              alt="Templates"
                              id="templates-selfservice-img2"
                              className="side-bar-submenu-img"
                            />
                            <span className="px-3">On-Demand Scan</span>
                          </div>
                        </li>
                      </Link>

                      {(userInfo.groups.includes(
                        CS_Hub_Security_Admin_Branch_2
                      ) ||
                        userInfo.groups.includes(
                          CS_Hub_AWS_Dynamo_Search_Request_Admin
                        ) ||
                        userInfo.groups.includes(
                          CS_Hub_AWS_Account_Request_Admin
                        )) && (
                        <Link to="/account-update">
                          <li id="account-update-selfservice">
                            <div className="d-flex align-items-center">
                              <img
                                src={accountUpdateIcon}
                                alt="Account-Update"
                                id="account-update-selfservice-img1"
                                className="side-bar-submenu-img"
                              />
                              <img
                                src={accountUpdateIconColor}
                                alt="Account-Update"
                                id="account-update-selfservice-img2"
                                className="side-bar-submenu-img"
                              />
                              <span className="px-3">
                                {t("account_update")}
                              </span>
                            </div>
                          </li>
                        </Link>
                      )}

                      <Link to="/trend-micro-onboarding">
                        <li id="trend-micro-onboarding">
                          <div className="d-flex align-items-center">
                            <img
                              src={TMIcon}
                              alt="Account-Update"
                              id="trend-micro-onboarding"
                              className="side-bar-submenu-img"
                            />
                            <img
                              src={TMIconColor}
                              alt="trend-micro-onboarding"
                              id="trend-micro-onboarding-img2"
                              className="side-bar-submenu-img"
                            />
                            <span className="px-3">Trend Micro Onboarding</span>
                          </div>
                        </li>
                      </Link>
                      {(userInfo.groups.includes(
                        CS_Hub_Security_Admin_Branch_2
                      ) ||
                        userInfo.groups.includes(
                          CS_Hub_AWS_Dynamo_Search_Request_Admin
                        ) ||
                        userInfo.groups.includes(
                          CS_Hub_AWS_Account_Request_Admin
                        )) && (
                        <Link to="/aws-host-patch-new-request">
                          <li id="aws-host-patch-request">
                            <div className="d-flex align-items-center">
                              <img
                                src={patchManagerIcon}
                                alt="AWS Patch Automation"
                                id="AWS-host-patch-management-img1"
                                className="side-bar-submenu-img"
                              />
                              <img
                                src={patchManagerColor}
                                alt="AWS Patch Automation"
                                id="AWS-host-patch-management-img2"
                                className="side-bar-submenu-img"
                              />
                              <span className="px-3">
                                {t("AWS Patch Automation")}
                              </span>
                            </div>
                          </li>
                        </Link>
                      )}
                      {/* <Link to="/azure-login/new-account">
                        <li id="trend-micro-onboarding">
                          <div className="d-flex align-items-center">
                            <img
                              src={templatecolor}
                              alt="Templates"
                              id="trend-micro-onboarding-img2"
                              className="side-bar-submenu-img"
                            />
                            <span className="px-3">Azure Login</span>
                          </div>
                        </li>
                      </Link> */}
                    </ul>
                  </div>
                </div>

                {/* {(userInfo.groups.includes(CS_Hub_Security_Admin_Branch_1) ||
                  userInfo.groups.includes(CS_Hub_Security_Admin_Branch_2)) && (
                  <Link
                    to="/security"
                    className={
                      "side-bar-link " +
                      (splitLocation[1] === "security"
                        ? "side-bar-link-active"
                        : "")
                    }
                  >
                    <img
                      src={hubsecurityIcon}
                      alt="Security"
                      className="side-bar-menu-logo"
                    />
                    <span className="side-bar-link-name">{t("security")}</span>
                  </Link>
                )} */}

                {
                  // (userInfo?.groups.includes(CS_Hub_Security_Admin_Branch_2) ||
                  (userInfo.groups.includes(CS_Hub_Security_Admin_Branch_1) ||
                    userInfo.groups.includes(CS_Hub_Security_Admin_Branch_2) ||
                    userInfo?.groups.includes(
                      CS_Hub_AWS_Account_Request_Admin
                    ) ||
                    userInfo?.groups.includes(
                      CS_Hub_Security_Tool_Exception_Approver_Group_2
                    ) ||
                    userInfo?.groups.includes(
                      CS_Hub_Security_Tool_Exception_Approver_Group_1
                    )) && (
                    <div
                      className={
                        " side-bar-link " +
                        (splitLocation[1] === "tool-exception-aws-view" ||
                        splitLocation[1] === "tool-exception-aws-add" ||
                        splitLocation[1] === "tool-exception-azure-view" ||
                        splitLocation[1] === "tool-exception-azure-add"
                          ? "side-bar-link-active"
                          : "")
                      }
                      id="self-service-menu"
                    >
                      <img
                        src={hubsecurityIcon}
                        alt="Security"
                        className="side-bar-menu-logo"
                      />
                      <span className="side-bar-link-name">
                        {t("Security")}
                      </span>
                      <div
                        className="arrow-left side-bar-submenu"
                        id="self-service-submenu"
                      >
                        <ul>
                          {/* <Link to="/security">
                            <li id="security-dashboard">
                              <div className="d-flex align-items-center">
                                <img
                                  src={dashboardIcon}
                                  alt="security-dashboard"
                                  id="tool-exception-Dashboard-img1"
                                  className="side-bar-submenu-img"
                                />
                                <img
                                  src={dashboardIconColor}
                                  alt="security-dashboard"
                                  id="tool-exception-Dashboard-img2"
                                  className="side-bar-submenu-img"
                                />
                                <span className="px-3">{t("Dashboard")}</span>
                              </div>
                            </li>
                          </Link> */}
                          <Link to="/tool-exception-index">
                            <li id="tool-exception">
                              <div className="d-flex align-items-center">
                                <img
                                  src={exception}
                                  alt="tool-exception"
                                  id="tool-exception-img1"
                                  className="side-bar-submenu-img"
                                />
                                <img
                                  src={exceptionColor}
                                  alt="tool-exception"
                                  id="tool-exception-img2"
                                  className="side-bar-submenu-img"
                                />
                                <span className="px-3">
                                  {t("Tool Exception")}
                                </span>
                              </div>
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                  )
                }

                <Link
                  to="/documents"
                  className={
                    "side-bar-link " +
                    (splitLocation[1] === "documents"
                      ? "side-bar-link-active"
                      : "")
                  }
                >
                  <img
                    src={documentsicon}
                    alt="Documents"
                    className="side-bar-menu-logo"
                  />
                  <span className="side-bar-link-name">{t("documents")}</span>
                </Link>

                {(userInfo.groups.includes(
                  CS_Hub_AWS_Account_Search_Read_Only_Security
                ) ||
                  userInfo.groups.includes(
                    CS_Hub_AWS_Dynamo_Search_Request_Admin
                  ) ||
                  userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ||
                  userInfo.groups.includes(CS_Hub_AWS_Notification_System)) && (
                  <div
                    className={
                      " side-bar-link " +
                      (splitLocation[1] === "notification" ||
                      splitLocation[1] === "aws-account-search" ||
                      splitLocation[1] === "azure-account-search" ||
                      splitLocation[1] === "aws-moriarty" ||
                      splitLocation[1] === "azure-moriarty"
                        ? "side-bar-link-active"
                        : "")
                    }
                    id="utilities-menu"
                  >
                    <img
                      src={utilitiesicon}
                      alt="Utilities"
                      className="side-bar-menu-logo"
                    />
                    <span className="side-bar-link-name">{t("utilities")}</span>

                    <div
                      className="arrow-left side-bar-submenu"
                      id="utilities-submenu"
                    >
                      <ul>
                        {(userInfo.groups.includes(
                          CS_Hub_AWS_Account_Search_Read_Only_Security
                        ) ||
                          userInfo.groups.includes(
                            CS_Hub_AWS_Dynamo_Search_Request_Admin
                          ) ||
                          userInfo.groups.includes(
                            CS_Hub_AWS_Account_Request_Admin
                          )) && (
                          <Link to="/aws-account-search">
                            <li id="account-search-utilities">
                              <div className="d-flex align-items-center">
                                <img
                                  src={accountsearchbg}
                                  alt="Account Search"
                                  id="account-search-utilities-img1"
                                  className="side-bar-submenu-img"
                                />
                                <img
                                  src={accountsearchcolor}
                                  alt="Account Search"
                                  id="account-search-utilities-img2"
                                  className="side-bar-submenu-img"
                                />
                                <span className="px-3">
                                  {t("account_search")}
                                </span>
                              </div>
                            </li>
                          </Link>
                        )}

                        {userInfo.groups.includes(
                          CS_Hub_AWS_Account_Request_Admin
                        ) && (
                          <Link to="/aws-moriarty">
                            <li id="moriarty-utilities">
                              <div className="d-flex align-items-center">
                                <img
                                  src={moriartytriggerbg}
                                  alt="Moriarty"
                                  id="moriarty-utilities-img1"
                                  className="side-bar-submenu-img"
                                />
                                <img
                                  src={moriartytriggercolor}
                                  alt="Moriarty"
                                  id="moriarty-utilities-img2"
                                  className="side-bar-submenu-img"
                                />
                                <span className="px-3">
                                  {t("moriarty_trigger")}
                                </span>
                              </div>
                            </li>
                          </Link>
                        )}

                        {/* Passport Manager Code -- TODO */}

                        {(userInfo.groups.includes(
                          CS_Hub_AWS_Notification_System
                        ) ||
                          userInfo.groups.includes(
                            CS_Hub_AWS_Account_Request_Admin
                          )) && (
                          <Link to="/notification-preference">
                            <li id="notification-utilities">
                              <div className="d-flex align-items-center">
                                <img
                                  src={notifybg}
                                  alt="Notification"
                                  id="notification-utilities-img1"
                                  className="side-bar-submenu-img"
                                />
                                <img
                                  src={notifycolor}
                                  alt="Notification"
                                  id="notification-utilities-img2"
                                  className="side-bar-submenu-img"
                                />
                                <span className="px-3">
                                  {t("notification")}
                                </span>
                              </div>
                            </li>
                          </Link>
                        )}
                        {/* {userInfo.groups.includes(
                          CS_Hub_AWS_Account_Request_Admin
                        ) && (
                          <Link to="/resource-query">
                            <li id="moriarty-utilities">
                              <div className="d-flex align-items-center">
                                <img
                                  src={moriartytriggerbg}
                                  alt="Moriarty"
                                  id="moriarty-utilities-img1"
                                  className="side-bar-submenu-img"
                                />
                                <img
                                  src={moriartytriggercolor}
                                  alt="Moriarty"
                                  id="moriarty-utilities-img2"
                                  className="side-bar-submenu-img"
                                />
                                <span className="px-3">Resource Query</span>
                              </div>
                            </li>
                          </Link>
                        )} */}

                        {userInfo.groups.includes(
                          CS_Hub_AWS_Account_Request_Admin
                        ) && (
                          <Link to="/create-cloud-eye-request">
                            <li id="moriarty-utilities">
                              <div className="d-flex align-items-center">
                                <img
                                  src={moriartytriggerbg}
                                  alt="Moriarty"
                                  id="moriarty-utilities-img1"
                                  className="side-bar-submenu-img"
                                />
                                <img
                                  src={moriartytriggercolor}
                                  alt="Moriarty"
                                  id="moriarty-utilities-img2"
                                  className="side-bar-submenu-img"
                                />
                                <span className="px-3">
                                  {t("cloudeye_user_access")}
                                </span>
                              </div>
                            </li>
                          </Link>
                        )}
                      </ul>
                    </div>
                  </div>
                )}

                {(userInfo?.groups?.includes(CS_Hub_PAM_Cost_Admins) ||
                  userInfo?.groups?.includes(CS_Hub_Cloud_Chargeback_Admins) ||
                  userInfo?.groups?.includes(CS_Hub_GCS_Chargeback_Admins)) && (
                  <div
                    className={
                      " side-bar-link " +
                      (splitLocation[1] === "billing-editor"
                        ? "side-bar-link-active"
                        : "")
                    }
                    id="ops-cost-menu"
                  >
                    <img
                      src={selfservicesicon}
                      alt="OPS Cost"
                      className="side-bar-menu-logo"
                    />
                    <span className="side-bar-link-name">{t("Billing")}</span>
                    <div
                      className="arrow-left side-bar-submenu"
                      id="ops-cost-submenu"
                    >
                      <ul>
                        {(userInfo?.groups?.includes(
                          CS_Hub_Cloud_Chargeback_Admins
                        ) ||
                          userInfo?.groups?.includes(
                            CS_Hub_GCS_Chargeback_Admins
                          )) && (
                          <Link to="/billing-edit">
                            <li id="ops-cost">
                              <div className="d-flex align-items-center">
                                <img
                                  src={opsCostImg}
                                  alt="Billings"
                                  id="ops-cost-img1"
                                  className="side-bar-submenu-img"
                                />

                                <img
                                  src={opsCostImgColor}
                                  alt="Billings"
                                  id="ops-cost-img2"
                                  className="side-bar-submenu-img"
                                />
                                <span className="px-3">{t("OPS Cost")}</span>
                              </div>
                            </li>
                          </Link>
                        )}
                        {(userInfo?.groups?.includes(CS_Hub_PAM_Cost_Admins) ||
                          userInfo?.groups?.includes(
                            CS_Hub_GCS_Chargeback_Admins
                          )) && (
                          <Link to="/aws-pamcosts">
                            <li id="pam-cost">
                              <div className="d-flex align-items-center">
                                <img
                                  src={pamCostIcon}
                                  alt="Billings"
                                  id="pam-cost-img1"
                                  className="side-bar-submenu-img"
                                />

                                <img
                                  src={pamCostIconColor}
                                  alt="Billings"
                                  id="pam-cost-img2"
                                  className="side-bar-submenu-img"
                                />
                                <span className="px-3">{t("PAM Cost")}</span>
                              </div>
                            </li>
                          </Link>
                        )}
                        {userInfo?.groups?.includes(
                          CS_Hub_GCS_Chargeback_Admins
                        ) && (
                          <Link to="/aws-billings">
                            <li id="ChargeBack">
                              <div className="d-flex align-items-center">
                                {/* <img
                                  src={billingbg}
                                  alt="Billings"
                                  className="side-bar-menu-logo"
                                /> */}
                                <i
                                  className="fa fa-usd side-bar-submenu-img  align-items-center"
                                  id="ChargeBack-img1"
                                ></i>

                                <i
                                  className="fa fa-usd side-bar-submenu-img  align-items-center"
                                  id="ChargeBack-img2"
                                ></i>
                                <span className=" px-3">
                                  {t("Charge Back")}
                                </span>
                              </div>
                            </li>
                          </Link>
                        )}
                      </ul>
                    </div>
                  </div>
                )}

                {/* <Link
                  to="/billings"
                  className={
                    "side-bar-link " +
                    (splitLocation[1] === "billings"
                      ? "side-bar-link-active"
                      : "")
                  }
                >
                  <img
                    src={billingbg}
                    alt="Billings"
                    className="side-bar-menu-logo"
                  />
                  <span className="side-bar-link-name">{t("billing")}</span>
                </Link> */}

                <Link
                  to="/help-support"
                  className={
                    "side-bar-link " +
                    (splitLocation[1] === "help-support"
                      ? "side-bar-link-active"
                      : "")
                  }
                >
                  <img
                    src={documentsicon}
                    alt="Documents"
                    className="side-bar-menu-logo"
                  />
                  <span className="side-bar-link-name">
                    {t("Help & Support")}
                  </span>
                </Link>
              </div>
            </div>
          </nav>
        </div>
      )}
    </>
  );
};

export default withRouter(SideBar);
