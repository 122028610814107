export const qRequestValidate = (values) => {
  let errors = {};
  if (!values?.globalId) {
    errors.globalId = "Global ID is required";
  }

  let isValidEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    values?.email
  );
  if (!values?.email) {
    errors.email = "Email address is required";
  } else if (isValidEmail === false) {
    errors.email = "Email address is invalid";
  }

  if (!values?.cloudService) {
    errors.cloudService = "Cloud Service is required";
  }
  // if (!values?.applicationName) {
  //   errors.applicationName = "Project Name / Application Name is required";
  // }
  if (!values?.additionalComments) {
    errors.additionalComments = "Additional Comments is required";
  }

  // if (!values?.supportingDocumentName) {
  //   errors.supportingDocumentName = "Supporting Documents is required";
  // }
  if (!values?.billingEmail) {
    errors.billingEmail = "Billing Email is required";
  }
  if (!values?.billingOpco) {
    errors.billingOpco = "Billing Opco is required";
  }
  if (!values?.billingSubOpco) {
    errors.billingSubOpco = "Billing SubOpco is required";
  }
  if (!values?.billingDepartment) {
    errors.billingDepartment = "Billing Department is required";
  }
  if (!values?.billTo) {
    errors.billTo = "Bill To is required";
  }
  if (values?.billTo === "Global IS (GISC)") {
    if (!values?.billingIo) {
      errors.billingIo = "Billing Io is required";
    }
  }
  if (values?.billTo === "GSID") {
    if (!values?.gsid) {
      errors.gsid = "GSID is required";
    }
  }
  if (values?.billTo === "Other Teams") {
    if (!values?.costCenter) {
      errors.costCenter = "Cost Center is required";
    }
  }
  if (!values?.billingRegion) {
    errors.billingRegion = "Billing Region is required";
  }

  return errors;
};
