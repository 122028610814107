import React, {
  useState,
  useReducer,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { Row, Col, Form, FormCheck } from "react-bootstrap";
import TextInput from "components/Forms/TextInput";
import SpinnerLoader from "components/Common/SpinnerLoader";
import { useTranslation } from "react-i18next";
import { AWSReducer, DefaultAWSData } from "./aws-reducer";
import StaticDropdown from "components/Forms/StaticDropdown";
import {
  qCloudServieList,
  staticbillingRegion,
  staticbillto,
} from "static-contents/ai-request-data";
import { useSelector } from "react-redux";
import { CS_Hub_AWS_Account_Request_Admin } from "roles/roles";
import { getApiCall } from "helper/getApiCall";
import { postApiCall } from "helper/postApiCall";
import { qRequestValidate } from "../validator/qRequestValidate";
import NewRequestSubmit from "./NewRequestSubmit";
import NewRequestCancel from "./NewRequestCancel";
import CustomModal from "components/Modal/CustomModal";
import BillingSubCompanyDropdown from "components/Forms/BillingSubCompanyDropdown";
import BillingDepartmentDropdown from "components/Forms/BillingDepartmentDropdown";
import BillingCompanyDropdown from "components/Forms/BillingCompanyDropdown";

const NewRequest = () => {
  const [formData, setFormData] = useReducer(AWSReducer, DefaultAWSData);
  const { t } = useTranslation();
  const ref = useRef();
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const idToken = userInfo.idToken;

  //fileUploadApiUrl
  const fileUploadApiUrl = `${process.env.REACT_APP_AI_FILE_UPLOAD}`;

  const [fileName, setFileName] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [baseFile, setBaseFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [billto, setBillto] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isDisclaimerAgreed, setIsDisclaimerAgreed] = useState(false);
  const [isCloudAccount, setIsCloudAccount] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [billingSubOpCompany, setBillingSubOpCompany] = useState([]);
  const [billingDepartment, setBillingDepartment] = useState([]);

  // Data Input Error
  const [dataInputError, setDataInputError] = useState({});
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const [apiError, setApiError] = useState(false);

  const [formSubmitError, setFormSubmitError] = useState(null);
  const [formSubmitRefUid, setFormSubmitRefUid] = useState(null);

  const handleChange = ({ target }) => {
    if (target.name === "billingOpco") {
      setFormData({
        type: "UPDATE_AI_REQUEST",
        payload: {
          [`billingSubOpco`]: "",
          [`billingDepartment`]: "",
        },
      });
      setBillingSubOpCompany([]);
      setBillingDepartment([]);
      let opCompany = target.value;
      setFormData({
        type: "UPDATE_AI_REQUEST",
        payload: {
          [`billingOpco`]: opCompany,
        },
      });
      if (opCompany !== "") {
        const getSubOpCompany = responseData.find((t) => t.opco === opCompany)
          .subopcod;
        setBillingSubOpCompany(getSubOpCompany);
      }
    }
    if (target.name === "billingSubOpco") {
      setFormData({
        type: "UPDATE_AI_REQUEST",
        payload: {
          [`billingDepartment`]: "",
        },
      });
      setBillingDepartment([]);
      let opSubCompany = target.value;
      setFormData({
        type: "UPDATE_AI_REQUEST",
        payload: {
          [`billingSubOpco`]: opSubCompany,
        },
      });
      if (opSubCompany !== "") {
        const getDepartment = billingSubOpCompany.find(
          (t) => t.subopco === opSubCompany
        ).dept;
        setBillingDepartment(getDepartment);
      }
    }
    if (target.name === "billingDepartment") {
      let department = target.value;
      setFormData({
        type: "UPDATE_AI_REQUEST",
        payload: {
          [`billingDepartment`]: department,
        },
      });
    }
    setFormData({
      type: "UPDATE_Q_REQUEST",
      payload: { [target.name]: target.value },
    });
  };

  const handleChangeTextArea = (name, value) => {
    setFormData({
      type: "UPDATE_Q_REQUEST",
      payload: {
        [name]: value,
      },
    });
  };

  useEffect(() => {
    setFormData({
      type: "UPDATE_Q_REQUEST",
      payload: {
        [`globalId`]: userInfo?.preferred_username,
        [`email`]: userInfo?.email,
        ["cloudService"]: qCloudServieList[0].value,
      },
    });
  }, []);
  useEffect(() => {
    setLoading(true);
    // adding query string fr oauth
    const queryObj = `{
        "submittedBy": "${userInfo?.email}",
        "type_of_user": ${
          userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ? 1 : 0
        }
      }`;
    const encodedString = Buffer.from(queryObj).toString("base64");
    // end

    const apiUrl = `${process.env.REACT_APP_AWS_ACCOUNT_REQUEST_BILLING_INFO}?enchash=${encodedString}`;
    getApiCall(apiUrl, idToken).then((response) => {
      setResponseData(response.data.body);
      setLoading(false);
    });
  }, []);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  const handleAddFormApi = (
    formSubmitApiUrl,
    idToken,
    requestBody,
    currentEpochTime
  ) => {
    postApiCall(formSubmitApiUrl, idToken, requestBody)
      .then((response) => {
        console.log("saveapi response", response);
        if (response.data.status === "Success") {
          setFormSubmitRefUid(`u-${currentEpochTime}`);
          setFormSubmitSuccess(true);
          setFormSubmitLoading(false);
          setLoading(false);
        } else {
          setFormSubmitError(
            "Failed to save, Contact Sony Global Cloud Team for Assistance"
          );
        }
      })
      .catch((err) => {
        setFormSubmitLoading(false);
        setLoading(false);
        setApiError(
          "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
        );
      });
  };

  const handleContinue = (e) => {
    e.preventDefault();
    const errors = qRequestValidate(formData.aiRequestDetails);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setLoading(true);
      setFormSubmitLoading(true);

      // Form Submission
      const currentEpochTime = Date.now();
      const formSubmitApiUrl = `${process.env.REACT_APP_AI_REQUEST_ADD}`;

      // Requestbody
      // handleFileUpload(currentEpochTime, formData.aiRequestDetails);

      const requestBody = {
        globalId: formData?.aiRequestDetails?.globalId,
        email: formData?.aiRequestDetails?.email,
        requestStatus: "Pending",
        // projectName: formData?.aiRequestDetails?.applicationName,
        cloudService: formData?.aiRequestDetails?.cloudService,
        additionalComments: formData?.aiRequestDetails?.additionalComments,
        billingOpco: formData?.aiRequestDetails?.billingOpco,
        billingSubOpco: formData?.aiRequestDetails?.billingSubOpco,
        billingDepartment: formData?.aiRequestDetails?.billingDepartment,
        billTo: formData?.aiRequestDetails?.billTo,
        billingRegion: formData?.aiRequestDetails?.billingRegion,
        billingEmail: formData?.aiRequestDetails?.billingEmail,
        uid: `u-${currentEpochTime}`,
        submittedBy: userInfo?.email,
        toolrequesttype: "AWSQ",
      };

      // console.log("first", requestBody);
      handleAddFormApi(
        formSubmitApiUrl,
        idToken,
        requestBody,
        currentEpochTime
      );
      // setTimeout(() => {
      //   handleAddFormApi(
      //     formSubmitApiUrl,
      //     idToken,
      //     requestBody,
      //     currentEpochTime
      //   );
      // }, 5000);
    }
  };

  return (
    <>
      <Row>
        <Col md={12} className=" mb-4 pb-1 text-center">
          <span className="page-content-title">
            {t("Amazon Q Developer Request")}
          </span>
        </Col>
      </Row>
      <Form onSubmit={handleContinue}>
        {loading && <SpinnerLoader />}

        {/* {formSubmitError !== null && (
    <Row>
      <Col className='mt-2'>
        <DangerAlert message={formSubmitError} />
      </Col>
    </Row>
  )} */}

        {loading === false && (
          <>
            <Row>
              <Col md={6}>
                <TextInput
                  isReadOnly={true}
                  label={t("Global ID")}
                  placeholder={t("Global ID")}
                  name="globalId"
                  value={formData?.aiRequestDetails?.globalId}
                  onChange={(e) => handleChange(e)}
                  isInvalid={dataInputError.globalId ? true : false}
                  invalidMessage={dataInputError.globalId}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  isReadOnly={true}
                  label={t("Email Address")}
                  name="email"
                  value={formData?.aiRequestDetails?.email}
                  onChange={(e) => handleChange(e)}
                  placeholder={t("Email Address")}
                  isInvalid={dataInputError.email ? true : false}
                  invalidMessage={dataInputError.email}
                />
              </Col>
              {/* <Col md={6}>
                <TextInput
                  label={t("Project Name / Application Name")}
                  name="applicationName"
                  value={formData?.aiRequestDetails?.applicationName}
                  onChange={(e) => handleChange(e)}
                  placeholder={t("Project Name / Application Name")}
                  isInvalid={dataInputError.applicationName ? true : false}
                  invalidMessage={dataInputError.applicationName}
                />
              </Col> */}

              <Col md={6}>
                <StaticDropdown
                  label={t("Cloud Service")}
                  disabled
                  defaultSelectMessage="Select Cloud"
                  name="cloudService"
                  value={formData?.aiRequestDetails?.cloudService}
                  onChange={(e) => handleChange(e)}
                  selectValues={qCloudServieList}
                  isInvalid={dataInputError.cloudService ? true : false}
                  invalidMessage={dataInputError.cloudService}
                />
              </Col>

              <Col md={6}>
                <BillingCompanyDropdown
                  label={"*" + t("billing_op_company") + ":"}
                  defaultSelectMessage={t("select_op_company")}
                  name="billingOpco"
                  value={formData?.aiRequestDetails?.billingOpco}
                  onChange={(e) => handleChange(e)}
                  selectValues={responseData}
                  isInvalid={dataInputError.billingOpco ? true : false}
                  invalidMessage={dataInputError.billingOpco}
                />
              </Col>

              <Col md={6}>
                <BillingSubCompanyDropdown
                  label={"*" + t("billing_subop_company") + ":"}
                  defaultSelectMessage={t("select_subop_company")}
                  name="billingSubOpco"
                  value={formData?.aiRequestDetails?.billingSubOpco}
                  onChange={(e) => handleChange(e)}
                  selectValues={billingSubOpCompany}
                  isInvalid={dataInputError.billingSubOpco ? true : false}
                  invalidMessage={dataInputError.billingSubOpco}
                />
              </Col>

              <Col md={6}>
                <BillingDepartmentDropdown
                  label={"*" + t("billing_dep") + ":"}
                  defaultSelectMessage="Select Department"
                  name="billingDepartment"
                  value={formData?.aiRequestDetails?.billingDepartment}
                  onChange={(e) => handleChange(e)}
                  selectValues={billingDepartment}
                  isInvalid={dataInputError.billingDepartment ? true : false}
                  invalidMessage={dataInputError.billingDepartment}
                />
              </Col>

              <Col md={6}>
                <StaticDropdown
                  label={"*" + t("bill_to") + ":"}
                  defaultSelectMessage={t("bill_to_msg")}
                  name="billTo"
                  value={formData?.aiRequestDetails?.billTo}
                  onChange={(e) => handleChange(e)}
                  selectValues={staticbillto}
                  isInvalid={dataInputError.billTo ? true : false}
                  invalidMessage={dataInputError.billTo}
                />
              </Col>

              {formData?.aiRequestDetails?.billTo === "GSID" ? (
                <Col md={6}>
                  <TextInput
                    label={t("GSID")}
                    name="gsid"
                    value={formData?.aiRequestDetails?.gsid}
                    onChange={(e) => handleChange(e)}
                    placeholder={t("GSID")}
                    isInvalid={dataInputError.gsid ? true : false}
                    invalidMessage={dataInputError.gsid}
                  />
                </Col>
              ) : null}
              {formData?.aiRequestDetails?.billTo === "Global IS (GISC)" && (
                <Col md={6}>
                  <TextInput
                    label="*IO:"
                    name="billingIo"
                    value={formData?.aiRequestDetails?.billingIo}
                    onChange={(e) => handleChange(e)}
                    placeholder="IO"
                    isInvalid={dataInputError.billingIo ? true : false}
                    invalidMessage={dataInputError.billingIo}
                  />
                </Col>
              )}
              {formData?.aiRequestDetails?.billTo === "Other Teams" && (
                <Col md={6}>
                  <TextInput
                    label="*Cost Center:"
                    name="costCenter"
                    value={formData?.aiRequestDetails?.costCenter}
                    onChange={(e) => handleChange(e)}
                    placeholder="Cost Center"
                    isInvalid={dataInputError.costCenter ? true : false}
                    invalidMessage={dataInputError.costCenter}
                  />
                </Col>
              )}

              <Col md={6}>
                <StaticDropdown
                  label={t("billing_region")}
                  defaultSelectMessage="Select Region"
                  name="billingRegion"
                  value={formData?.aiRequestDetails?.billingRegion}
                  onChange={(e) => handleChange(e)}
                  selectValues={staticbillingRegion}
                  isInvalid={dataInputError.billingRegion ? true : false}
                  invalidMessage={dataInputError.billingRegion}
                />
              </Col>
              <Col md={6} className="">
                <TextInput
                  label={t("Billing email")}
                  name="billingEmail"
                  value={formData?.aiRequestDetails?.billingEmail}
                  onChange={(e) => handleChange(e)}
                  placeholder={t("Billing email")}
                  isInvalid={dataInputError.billingEmail ? true : false}
                  invalidMessage={dataInputError.billingEmail}
                />
              </Col>

              <Col md={12}>
                <Form.Group className="mb-3" controlId="form-subject">
                  <TextInput
                    as="textarea"
                    label={t("Additional Comments")}
                    name="additionalComments"
                    value={formData?.aiRequestDetails?.additionalComments}
                    onChange={(e) =>
                      handleChangeTextArea("additionalComments", e.target.value)
                    }
                    placeholder={t("Additional Comments")}
                    isInvalid={dataInputError.additionalComments ? true : false}
                    invalidMessage={dataInputError.additionalComments}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={10}>
                <Form.Group className="mb-3" controlId="disclaimerCheckbox">
                  {/* todo:rd fix for link */}
                  <Form.Check
                    type="checkbox"
                    label={
                      <div style={{ fontSize: 12 }}>
                        {t(
                          `I consent and acknowledge that I have provided all the details to the best of my knowledge by adhering to SonyAI compliance guidelines - `
                        )}
                        <a
                          href="https://sony.sharepoint.com/:b:/s/S004-GISC-Cloud/EZoBZUbXQ25DpM2dJpaf-CkB3vrZebKZ4r-fCYCCnd9-qw?e=hWL2M6"
                          target="blank"
                        >
                          Guideliness can be found here.
                        </a>
                      </div>
                    }
                    defaultChecked={isDisclaimerAgreed}
                    onChange={(e) => {
                      setIsDisclaimerAgreed(!isDisclaimerAgreed);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="d-flex justify-content-center">
                <ul className="px-0 btn-ul">
                  <li>
                    <button
                      type="button"
                      className="teal-bordered-btn"
                      onClick={() => {
                        setShowCancelModal(true);
                      }}
                    >
                      {t("cancel")}
                    </button>
                  </li>
                  <li>
                    <button
                      type="submit"
                      className={`teal-filled-btn ${
                        !isDisclaimerAgreed ? "disable-div" : ""
                      }`}
                      disabled={isDisclaimerAgreed ? false : true}
                    >
                      {t("submit")}
                    </button>
                  </li>
                </ul>
              </Col>
            </Row>
          </>
        )}
      </Form>
      {formSubmitSuccess && (
        <CustomModal
          showModal={true}
          children={
            <NewRequestSubmit
              refUid={formSubmitRefUid}
              errorMessageValue={formSubmitError}
            />
          }
        />
      )}
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<NewRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
    </>
  );
};

export default NewRequest;
