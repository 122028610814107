import React from "react";

const ShowWarningText = ({ closeModalParent, noteEnvironmentText }) => {
  return (
    <div className="d-flex justify-content-center flex-column">
      <ul className="btn-ul">
        <li>
          <button
            type="button"
            className="teal-bordered-btn"
            onClick={() => {
              closeModalParent(false);
            }}
          >
            Ok
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ShowWarningText;
